<template>
    <v-row class="border-card">
        <v-dialog v-model="dialog" scrollable persistent max-width="700px" class="borde-card">
            <v-card class="borde-card">
                <perfect-scrollbar style="max-height:100%;">    
                    <v-card class="borde-card" elevation="0">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="logo" class="imgModal" />
                                    <h2 class="titleModal">{{ title }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <div id="padre" v-show="!cargaModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                <v-container v-show="cargaModal" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>

                                    <v-row>
                                        <v-col cols="12" xs="12" sm="8" md="8" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Nombre de la empresa" 
                                                    class="pa-0 ma-0" 
                                                    v-model="empresa.nombre" 
                                                    :error-messages="errors" 
                                                    required
                                                    id="txtNombre"
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Clave" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Clave" 
                                                    class="pa-0 ma-0" 
                                                    v-model="empresa.clave"
                                                    :error-messages="errors" 
                                                    required
                                                    id="txtClave"
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Razón Social" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Razón Social" 
                                                    class="pa-0 ma-0" 
                                                    v-model="empresa.razon_social" 
                                                    :error-messages="errors" 
                                                    required
                                                    id="txtRS"
                                                    >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Cliente"
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="empresa.cliente_id"
                                                    class="pa-0 ma-0"
                                                    required
                                                    id="txtCliente"
                                                    :error-messages="errors" 
                                                    no-data-text="Datos no disponibles"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="RFC" rules="required|rfc">
                                                <v-text-field 
                                                    outlined 
                                                    label="RFC" 
                                                    class="pa-0 ma-0" 
                                                    v-model="empresa.rfc" 
                                                    :error-messages="errors" 
                                                    required
                                                    id="txtRFC"
                                                >    
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="URL de Servidor" rules="https_puerto">
                                                <v-text-field 
                                                    outlined
                                                    label="URL de servidor" 
                                                    class="pa-0 ma-0" 
                                                    v-model="empresa.url_server_asp" 
                                                    :error-messages="errors" 
                                                    required
                                                    id="txtURL"
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Dirección" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Dirección" 
                                                    class="pa-0 ma-0" 
                                                    v-model="empresa.direccion" 
                                                    :error-messages="errors" 
                                                    required
                                                    id="txtDireccion"
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="CP" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="Código postal"
                                                    persistent-hint
                                                    v-model="busca_cp"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                    @keyup.enter="busca_codigos()"
                                                    v-on:keydown.tab="busca_codigos()"
                                                    v-mask="'#####'"
                                                    id="txtCP"
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                            <v-text-field
                                                outlined
                                                label="Estado"
                                                v-model="estado"
                                                disabled
                                                class="pa-0 ma-0"
                                                id="txtEstado"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                            <v-text-field
                                                outlined
                                                label="Municipio"
                                                v-model="municipio"
                                                disabled
                                                class="pa-0 ma-0"
                                                id="txtMunicipio"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        
                                        <v-col cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Colonia" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Colonia"
                                                    :items="colonias"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="empresa.colonia_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                    id="txtColonia"
                                                    no-data-text="Datos no disponibles"
                                                >
                                                    <template v-if="mColonia"  v-slot:append-item>
                                                        <v-list-item>             
                                                            <v-list-item-content class="cursor-pointer"  @click="abrirModalColonia()">
                                                                <v-list-item-title style="color:#007EFF">Agregar colonia</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Telefono" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Teléfono" 
                                                    class="pa-0 ma-0" 
                                                    v-mask="'(###) ###-####'"
                                                    v-model="empresa.telefono_contacto" 
                                                    :error-messages="errors" 
                                                    required
                                                    id="txtTelefono"
                                                >    
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Contacto" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Contacto" 
                                                    class="pa-0 ma-0" 
                                                    v-model="empresa.nombre_contacto" 
                                                    :error-messages="errors" 
                                                    required
                                                    id="txtContacto"
                                                > 
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Correo Contacto" rules="required|email" >
                                                <v-text-field 
                                                    outlined
                                                    label="Correo Contacto" 
                                                    class="pa-0 ma-0" 
                                                    v-model="empresa.correo_contacto" 
                                                    :error-messages="errors" 
                                                    required
                                                    id="txtCorreoContacto"
                                                >    
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="action=='create'" cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                            <v-text-field
                                                outlined
                                                readonly
                                                label="Seleccionar logo"
                                                @click="almacenarImagen"
                                                v-model="nombreImagen"
                                            >
                                            <template slot="append">
                                                <v-icon @click="almacenarImagen" class="tamIcono">attach_file</v-icon>
                                            </template>
                                            </v-text-field>
                                            <input
                                                type="file"
                                                style="display: none"
                                                ref="imagen"
                                                accept="image/*"
                                                id="avatar"
                                                @change="onFilePickedImagen"
                                            />
                                        </v-col>
                                        <v-col v-if="action=='update' && empresa.logo == null" cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                            <v-text-field
                                                outlined
                                                readonly
                                                label="Seleccionar logo"
                                                @click="almacenarImagen"
                                                v-model="nombreImagen"
                                            >
                                            <template slot="append">
                                                <v-icon @click="almacenarImagen" class="tamIcono">attach_file</v-icon>
                                            </template>
                                            </v-text-field>
                                            <input
                                                type="file"
                                                style="display: none"
                                                ref="imagen"
                                                accept="image/*"
                                                id="avatar"
                                                @change="onFilePickedImagen"
                                            />
                                        </v-col>
                                        <v-col v-if="action=='update' && empresa.logo != null" cols="12" xs="12" sm="5" md="5" class="pt-0 pb-0 ma-0">
                                            <v-text-field
                                                outlined
                                                readonly
                                                label="Seleccionar logo"
                                                @click="almacenarImagen"
                                                v-model="nombreImagen"
                                            >
                                            <template slot="append">
                                                <v-icon @click="almacenarImagen" class="tamIcono">attach_file</v-icon>
                                            </template>
                                            </v-text-field>
                                            <input
                                                type="file"
                                                style="display: none"
                                                ref="imagen"
                                                accept="image/*"
                                                id="avatar"
                                                @change="onFilePickedImagen"
                                            />
                                        </v-col>
                                        <v-col v-if="action=='update' && empresa.logo != null" cols="12" xs="12" sm="1" md="1" class="pt-2">
                                            <v-tooltip bottom >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" @click="eliminarLogo()" color="#1E2245" elevation="0" dark fab small>
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Eliminar logo</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="store()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </perfect-scrollbar>
            </v-card>
        </v-dialog>

        <template class="borde-card">
            <v-dialog v-model="dialogColonia" persistent max-width="500px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalColonia" :style="'display: none;'"></v-btn>
                </template>
                <v-card class="borde-card">
                    <ValidationObserver ref="formColonia" v-slot="{ invalid }">
                        <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                            <div class="headerModalStart">
                                <h2 class="titleModal_700_18">Agregar colonia</h2>
                            </div>
                        </v-card-title>
                        <v-card-text class="card_texto mt-5">
                            <div id="padre" v-show="isLoadingModalColonia">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoadingModalColonia" grid-list-md id="contenedor">
                                <v-row class="mt-0">
                                    
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="Código postal" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Código postal" 
                                                class="pa-0 ma-0" 
                                                v-model="busca_cp" 
                                                :error-messages="errors" 
                                                required
                                                disabled
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="Colonia" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Colonia" 
                                                class="pa-0 ma-0" 
                                                v-model="nombreColonia" 
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModalColonia()" 
                                :disabled="isSavingColonia"
                            >
                                Cancelar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="guardarColonia()" 
                                :disabled="invalid" 
                                :loading="isSavingColonia"
                            >
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>
    </v-row>
</template>

<script>
import apiClientes from "@/api/clientes";
import apiEmpresas from "@/api/empresas";
import catalogoApis from '@/api/catalogos';
import Notify from '@/plugins/notify';
import queries from '@/queries/Empresas';
import GetUrlImg from "@/plugins/Urlimagen";
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';

export default {
    components: {
        VuetifyDatePicker,
    },
    props: ['title', 'objEmpresa', 'action','dialog'],
    data() {
        return {
            empresa: {
                id                      : null,
                nombre                  : '',
                razon_social            : '',
                rfc                     : '',
                direccion               : '',
                url_server_asp          : '',
                cliente_id              : null,
                estado_id               : null,
                municipio_id            : null,
                cp_id                   : null,
                colonia_id              : null,
                tipo                    : '', //gdth o nominas
                clave                   : '',
                registro_patronal_id    : null,
                nombre_comercial        : '',
                giro_cif                : '',
                giro_descripcion        : '',
                banco_empresa_f_id      : null,
                concepto_f              : '',
                concepto_f1             : '',
                metodo_de_pago_id       : null,
                terminacion_cta_cliente : '',
                fecha_contrato          : '',
                objeto_contractural     : '',
                representante_legal     : '',
                divisa                  : '',
                inicio_operaciones      : '',
                tipo_nomina_id          : null,
                telefono_contacto       : '',
                envio_documentacion     : '',
                observaciones_generales : '',
                nombre_contacto         : '',
                correo_contacto         : '',
                solo_pagos              : false,
                logo                    : '',
                update_logo             : '',
            },
            datosLogin          : null,
            rol                 : null,
            clientes            : [],
            estado_id           : null,
            municipio_id        : null,
            cp_id               : null,
            estados             : [],
            municipios          : [],
            ciudades            : [],
            codigosPostales     : [],
            colonias            : [],
            busca_cp            : "",
            municipio           : "",
            estado              : "", 
            isSaving            :false,
            cargaModal          : false,
            boolWatch           : true,
            archivoImagen       : null,
            nombreImagen        : null,
            urlImg              : null,
            logo                : null,
            loadingGuardar      : false,

            mColonia                : false,
            dialogColonia           : false,
            isLoadingModalColonia   : false,
            nombreColonia           : null,
            isSavingColonia         : false,
            dialogSueldo            : false,
        }
    },
    watch:{
        busca_cp: function(val){
            if(this.boolWatch){
                if(val !="" && val!=null ){
                    if(val.length == 5){
                        this.busca_codigos();
                        this.mColonia = true;
                    }
                    else{
                        this.estado = "";
                        this.municipio = "";
                        this.colonias = [];
                        this.mColonia = false;
                    }
                }
            }
        },
        
    },
    methods: {
        /**
         * @method cerrarModal Este método sirve para cerrar el modal.
         * @description Este método sirve para cerrar el modal de Empresa.
         */
        cerrarModal() {
            this.$emit("cerrarModal", "guardarCerrar");
            this.isSaving   = false;
        },

        /**
         * @method store Este método sirve para guardar o actualizar una empresa
         * @description Este método realiza la petición de l API para guardar o actualizar una empresa.
         */
        store() {
            this.isSaving = true
                this.loadingGuardar = true;
                this.empresa.rfc=this.empresa.rfc.toUpperCase();

                let formData = new FormData();

                formData.append('nombre', this.empresa.nombre.toString());
                formData.append('razon_social', this.empresa.razon_social.toString());
                formData.append('rfc',this.empresa.rfc.toString());
                formData.append('direccion', this.empresa.direccion.toString());
                formData.append('cliente_id',this.empresa.cliente_id);
                formData.append('estado_id',this.empresa.estado_id);
                formData.append('municipio_id', this.empresa.municipio_id);
                formData.append('cp_id',this.empresa.cp_id);
                formData.append('colonia_id', this.empresa.colonia_id);
                formData.append('tipo', 'nominas');
                formData.append('clave', this.empresa.clave.toString());
                formData.append('telefono_contacto', this.empresa.telefono_contacto.toString()); 
                formData.append('nombre_contacto', this.empresa.nombre_contacto.toString());
                formData.append('correo_contacto', this.empresa.correo_contacto.toString());
                
                if(this.empresa.nombre_comercial != null){
                    formData.append('nombre_comercial', this.empresa.nombre_comercial.toString());
                }
                if(this.empresa.giro_descripcion != null){
                    formData.append('giro_descripcion', this.empresa.giro_descripcion.toString());
                }

                if (this.archivoImagen != null) {
                    formData.append("archivo", this.archivoImagen);
                    if (this.empresa.update_logo != null) {
                        formData.append("update_logo", this.empresa.update_logo);
                    }
                }
                if(this.empresa.url_server_asp != null){
                    formData.append('url_server_asp',this.empresa.url_server_asp.toString());
                }

                if(this.action != "update") {
                    apiEmpresas.addEmpresa(formData)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "La empresa se guardó satisfactoriamente.");
                        this.loadingGuardar = false;
                        this.$emit("cerrarModal", "guardarCerrar");
                        this.isSaving = false;
                    })
                    .catch(err => {
                        this.loadingGuardar = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                } else {
                    formData.append('id',this.empresa.id);
                    apiEmpresas.updateEmpresaP(formData)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "La empresa se actualizó satisfactoriamente.");
                        this.loadingGuardar = false;
                        this.$emit("cerrarModal", "guardarCerrar");
                        this.isSaving = false;
                    })
                    .catch(err => {
                        this.loadingGuardar = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
        },
        
        /**
         * @method cancel Este método sirve para cerrar el modal
         * @description Este método sirve para cerrar el modal de empresa cuando se le da clic al botón "Cancelar"
         */
        cancel() {
            this.cargaModal = false;
            this.$modal.hide('empresaModal');
            this.$emit("cerrarModal", "cancelar");
        },

        /**
         * @method getClientes Este método para clientes
         * @description Este método realiza una petición a la API de clientes y llena el array "clientes". 
         */
        async getClientes(){
            let parametros = { activo:true, paginate:false, order:"asc"}
            await apiClientes.getClientes(parametros).then((response) => {
                this.clientes = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Clientes.");
            });
        },

        /**
         * @method getCP Este método sirve para el codigo postal.
         * @description Este método sirve para el codigo postal. Se envia el id.
         * @param {int} id Id de código postal.
         */
        async getCP(id) {
            await catalogoApis.getCodigoPostal(id).then((response) => {
                this.busca_cp = response.nombre;
                this.busca_codigos();
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los codigos postales.");
            });
        },

        /**
         * @method busca_codigos Método que se ejecuta buscar el estado y la ciudad de acuedo al CP.
         * @description Método que se ejecuta buscar el estado y la ciudad de acuerdo al CP.
         */
        async busca_codigos() {
            let self = this;
            let params = {
                activo: true,
                nombre: this.busca_cp,
                include_padres: true,
            };
            await catalogoApis.getCP(params).then((response) => {
                let result = response.data;

                if (result.length == 0) {
                    Notify.ErrorToast("No se encontro el Código postal.");
                    this.estado     = "";
                    this.municipio  = "";
                    this.colonias   = [];
                    this.mColonia   = false;
                    return;
                }
                this.estado                 = result[0].nomb_estado;
                this.municipio              = result[0].nomb_mun;
                this.empresa.estado_id      = result[0].estado_id;
                this.empresa.municipio_id   = result[0].municipio_id;
                this.empresa.cp_id          = result[0].id;

                catalogoApis.getColonias({ codigo_postal_id: result[0].id, order: "asc", paginate:false }).then((response) => {
                    self.colonias = response.data;
                    if(self.action == "update"){
                        this.cargaModal = true;
                    }
                })
                .catch((e) => {
                    Notify.ErrorToast(
                        "Se presento un problema al cargar las colonias."
                    );
                return;
                });
            })
            .catch((e) => {
                console.log(e);
                Notify.ErrorToast(
                    "Ocurrió un error al cargar los estados y ciudades."
                );
            });
        },

        /**
         * @method getColonias Método que sirve para realizar petición a la API de colonias.
         * @description Método que sirve para realizar petición a la API de colonias y llenar el array de "colonias"
         * @param {int} cp_id Id de código postal.
         */
        async getColonias(cp_id = null){
            catalogoApis.getColonias({ codigo_postal_id: cp_id, order: "asc", paginate:false }).then((response) => {
                this.colonias = response.data;
            })
            .catch((e) => {
                Notify.ErrorToast(
                    "Se presento un problema al cargar las colonias."
                );
            return;
            });
        },

        abrirModalColonia(){
            this.dialogColonia = true;
            this.isLoadingModalColonia = false;
        },
        cerrarModalColonia(){
            this.dialogColonia = false;
            this.nombreColonia = null;
            this.isSavingColonia = false;
        },
        guardarColonia(){
            this.$refs.formColonia.validate().then(success => {
                this.isSavingColonia = true;
                if(!success) {
                    this.isSavingColonia = false;
                    return;
                }
                
                let parametros = {
                    nombre : this.nombreColonia,
                    codigo_postal_id : this.empresa.cp_id
                };
                
                catalogoApis.addColonia(parametros).then((response) => {
                    Notify.Success("Operación exitosa", "La calonia se agrego correctamnte.");
                    this.busca_codigos()
                    this.cerrarModalColonia();
                    this.empresa.colonia_id = response.data.id;
                    this.$nextTick(() => {
                        this.$refs.formColonia.reset();
                    });
                })
                .catch(err => {
                    this.isSavingColonia = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                });
            });
        },

        /**
         * @method almacenarImagen
         * @description Método que sirve para dar click y se abra una ventana para agregar una imagen
         */
        almacenarImagen() {
            this.$refs.imagen.click();
        },

        /**
         * @method onFilePickedImagen Método para almacenar una imagen
         * @description Este método realiza el procedimiento de almacenar un archivo de tipo imagen en una variable "archivoImagen"
         * @param {object} evento Objeto imagen. 
         */
        onFilePickedImagen(evento) {
            const img = evento.target.files;
            if (img[0] !== undefined) {
                this.nombreImagen = img[0].name;
                if (this.nombreImagen.lastIndexOf(".") <= 0) {
                    return;
                }
                const archivo = new FileReader();
                archivo.readAsDataURL(img[0]);
                archivo.addEventListener("load", () => {
                    this.archivoImagen = img[0];
                });
            } else {
                this.nombreImagen = "";
                this.archivoImagen = null;
            }
        },

        /**
         * @method eliminarLogo Método para eliminar el logo de la empresa.
         * @description Este método sirve para eliminar el logo de la empresa.
         */
        eliminarLogo(){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el logo de la empresa?",
                "Eliminar",
                () => {
                    let param = {id:this.empresa.id, rol_name:"admin-empresa"};
                    apiEmpresas.deleteLogo(param).then(response => {
                        Notify.Success("Logo eliminado", "El logo ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.empresa.refetch();
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )           
        }
    },

    /**
     * @name apollo:empresa
     * @description GrapQL apollo, query empresa, realiza la petición a empresa, se le para el id de la empresa
     */
    apollo: {
        empresa: {
            query       : queries.getEmpresaQuery,
            fetchPolicy : 'network-only',
            variables() {
                return {
                    id : parseInt(this.objEmpresa.id)
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.busca_cp   = this.empresa.codigoPostal ? this.empresa.codigoPostal.nombre : null;
                this.estado     = this.empresa.estado ? this.empresa.estado.nombre : null;
                this.municipio  = this.empresa.municipio ? this.empresa.municipio.nombre : null;
                this.getColonias(this.empresa.cp_id)
                this.cargaModal = true;
                if(this.action == 'update'){
                    if (this.empresa.logo == null) {
                        this.logo = "/static/modal/empresaSucursalUpdate.svg";
                    } else {
                        this.empresa.update_logo = this.empresa.logo;
                        this.logo = this.urlImg + this.empresa.logo;

                    }
                }

                setTimeout(() => {
                    this.boolWatch = true
                }, 1000);
            }
        }
    },
   
    
    /**
     * @description create(). Se inician los valores que se requieren antes de montar todos los componentes.
    */
    async created(){
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        this.urlImg = GetUrlImg.getURL();
        this.empresa.update_logo = null;
        this.empresa.logo = null;
        if(this.action=="update"){
            this.boolWatch = false;
        }
        if (this.rol == "root") {
            this.getClientes();
        }

        if (this.rol == "admin") {
            this.empresa.cliente_id = this.datosLogin.cliente_id;
        }
        


        if(this.objEmpresa != null) {
            this.$apollo.queries.empresa.skip = false;
        } 
    },

    /**
     * @description mounted(). Se inician los valores que se requieren al montar los componentes en la vista.
    */
    mounted() {
        if(this.action == 'create'){
            this.logo = "/static/modal/empresaSucursalCreate.svg"; 
        }
        
        if(this.action != "update"){
            this.cargaModal = true;
        }
        
    },
    updated() {
        this.boolWatch  = true;
    },
    beforeDestroy(){
        this.boolWatch = false;
    },
}
</script>

<style scoped>
    #loading{
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cursor-pointer{
      cursor:pointer;
    }
</style>